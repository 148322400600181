var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.second_routes.length > 0
    ? _c("div", [
        _c(
          "div",
          { staticClass: "sidebar-container" },
          [
            _c(
              "el-scrollbar",
              { attrs: { "wrap-class": "scrollbar-wrapper" } },
              [
                _c(
                  "el-menu",
                  {
                    attrs: {
                      "default-active": _vm.activeMenu,
                      "background-color": _vm.variables.menuBg,
                      "text-color": _vm.variables.menuText,
                      "active-text-color": _vm.variables.menuActiveText,
                      collapse: _vm.isCollapse,
                      "collapse-transition": false,
                      mode: "vertical",
                    },
                  },
                  [
                    _vm.isCollapse
                      ? [
                          _c(
                            "el-menu",
                            {
                              attrs: {
                                "default-active": _vm.activeMenu,
                                collapse: _vm.isCollapse,
                                "background-color": _vm.variables.menuBg,
                                "text-color": _vm.variables.menuText,
                                "unique-opened": false,
                                "active-text-color":
                                  _vm.variables.menuActiveText,
                                "collapse-transition": false,
                                mode: "vertical",
                              },
                            },
                            _vm._l(_vm.second_routes, function (route) {
                              return _c("sidebar-item", {
                                key: route.path,
                                attrs: { item: route, "base-path": route.path },
                              })
                            }),
                            1
                          ),
                        ]
                      : [
                          _vm._l(_vm.second_routes, function (route) {
                            return [
                              !route.hidden &&
                              _vm.getFirstShowingChild(route.children, route)
                                ? [
                                    route.meta
                                      ? _c(
                                          "el-menu-item",
                                          {
                                            key: route.path,
                                            attrs: {
                                              index: _vm.resolvePath(route),
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.jumpTo(route)
                                              },
                                            },
                                          },
                                          [
                                            _c("item", {
                                              attrs: {
                                                icon:
                                                  route.meta && route.meta.icon,
                                                title: route.meta.title,
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ]
                                : _vm._e(),
                            ]
                          }),
                        ],
                  ],
                  2
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm.third_routes.length > 0 && !_vm.isCollapse
          ? _c(
              "div",
              { staticClass: "sub-sidebar-container" },
              [
                _c(
                  "el-scrollbar",
                  { attrs: { "wrap-class": "scrollbar-wrapper" } },
                  [
                    _c(
                      "el-menu",
                      {
                        attrs: {
                          "default-active": _vm.activeSubMenu,
                          collapse: _vm.isCollapse,
                          "unique-opened": false,
                          "collapse-transition": false,
                          mode: "vertical",
                        },
                      },
                      _vm._l(_vm.third_routes, function (route) {
                        return _c("sidebar-item", {
                          key: route.path,
                          attrs: {
                            item: route,
                            "base-path": route.path,
                            "is-nest": true,
                          },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }