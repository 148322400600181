var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-wrapper", class: _vm.classObj },
    [
      _c(
        "div",
        { staticClass: "top-menu-container", staticStyle: { height: "50px" } },
        [
          _c(
            "div",
            { staticClass: "top-logo" },
            [
              _vm.showLogo
                ? _c("logo", { attrs: { collapse: _vm.isCollapse } })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "top-toolbar" },
            [
              _c("hamburger", {
                staticClass: "hamburger-container",
                attrs: { "is-active": _vm.sidebar.opened },
                on: { toggleClick: _vm.toggleSideBar },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "top-menu" }, [_c("top-menu")], 1),
          _c("div", { staticClass: "top-avatar" }, [
            _c(
              "div",
              { staticClass: "right-menu" },
              [
                _c(
                  "el-dropdown",
                  {
                    staticClass: "avatar-container",
                    attrs: { trigger: "click" },
                  },
                  [
                    _c("div", { staticClass: "avatar-wrapper" }, [
                      _c("img", {
                        staticClass: "user-avatar",
                        attrs: { src: _vm.avatar + "?imageView2/1/w/80/h/80" },
                      }),
                      _c("i", {
                        staticClass: "el-icon-caret-bottom",
                        staticStyle: { color: "#ffffff" },
                      }),
                    ]),
                    _c(
                      "el-dropdown-menu",
                      {
                        staticClass: "user-dropdown",
                        attrs: { slot: "dropdown" },
                        slot: "dropdown",
                      },
                      [
                        _c(
                          "el-dropdown-item",
                          {
                            nativeOn: {
                              click: function ($event) {
                                return _vm.logout($event)
                              },
                            },
                          },
                          [
                            _c("span", { staticStyle: { display: "block" } }, [
                              _vm._v("退出"),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _vm.device === "mobile" &&
      _vm.sidebar.opened &&
      _vm.second_routes.length > 0
        ? _c("div", {
            staticClass: "drawer-bg",
            on: { click: _vm.handleClickOutside },
          })
        : _vm._e(),
      _c("sidebar"),
      _c(
        "div",
        { staticClass: "main-container", class: _vm.classSidebar },
        [
          _c(
            "div",
            { class: { "fixed-header": _vm.fixedHeader } },
            [_c("navbar")],
            1
          ),
          _c("app-main"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }