export function companyName(companyId, companies) {
  const result = companies.filter(c => c.id === companyId)
  return result.length > 0 ? result[0].name : ''
}

export function pdfFormName(pdfFormId, forms) {
  const result = forms.filter(c => c.id === pdfFormId)
  return result.length > 0 ? result[0].name : ''
}

export function surveyTitle(surveyId, surveies) {
  const result = surveies.filter(c => c.id === surveyId)
  return result.length > 0 ? result[0].title : ''
}

export function renderProductName(productId, products) {
  const result = products.filter(c => c.id === productId)
  return result.length > 0 ? ((result[0].name ? result[0].name : result[0].company.name) + ' [' + result[0].id + ']') : ''
}

export function companyLogo(companyId, companies) {
  const result = companies.filter(c => c.id === companyId)
  return result.length > 0 ? result[0].signLogoUrl : ''
}

export function unescape(html) {
  return !html ? '' : html.replace(/(?:\r\n|\r|\n)/g, '<br />')
}

export function getNameByCode(code, list) {
  const match = list.filter(t => t.code === code)
  return match.length > 0 ? match[0].name : ''
}