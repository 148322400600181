const getters = {
  sidebar: state => state.app.sidebar,
  device: state => state.app.device,
  token: state => state.user.token,
  avatar: state => state.user.avatar,
  name: state => state.user.name,
  roles: state => state.user.roles,
  permission_routes: state => state.permission.routes,
  second_routes: state => state.permission.second_routes,
  third_routes: state => state.permission.third_routes,
  uploadUrl: state => state.settings.uploadUrl
}
export default getters
