var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "login-container" },
    [
      _c(
        "el-form",
        {
          ref: "loginForm",
          staticClass: "login-form",
          attrs: {
            model: _vm.loginForm,
            rules: _vm.loginRules,
            "auto-complete": "on",
            "label-position": "left",
          },
        },
        [
          _c("div", { staticClass: "title-container" }, [
            _c("h3", { staticClass: "title" }, [_vm._v("MoneyPlaza CMS")]),
          ]),
          _c(
            "el-form-item",
            { attrs: { prop: "username" } },
            [
              _c(
                "span",
                { staticClass: "svg-container" },
                [_c("svg-icon", { attrs: { "icon-class": "user" } })],
                1
              ),
              _c("el-input", {
                ref: "username",
                attrs: {
                  placeholder: "Username",
                  name: "username",
                  type: "text",
                  tabindex: "1",
                  "auto-complete": "on",
                },
                model: {
                  value: _vm.loginForm.username,
                  callback: function ($$v) {
                    _vm.$set(_vm.loginForm, "username", $$v)
                  },
                  expression: "loginForm.username",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "password" } },
            [
              _c(
                "span",
                { staticClass: "svg-container" },
                [_c("svg-icon", { attrs: { "icon-class": "password" } })],
                1
              ),
              _c("el-input", {
                key: _vm.passwordType,
                ref: "password",
                attrs: {
                  type: _vm.passwordType,
                  placeholder: "Password",
                  name: "password",
                  tabindex: "2",
                  "auto-complete": "on",
                },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.handleLogin($event)
                  },
                },
                model: {
                  value: _vm.loginForm.password,
                  callback: function ($$v) {
                    _vm.$set(_vm.loginForm, "password", $$v)
                  },
                  expression: "loginForm.password",
                },
              }),
              _c(
                "span",
                { staticClass: "show-pwd", on: { click: _vm.showPwd } },
                [
                  _c("svg-icon", {
                    attrs: {
                      "icon-class":
                        _vm.passwordType === "password" ? "eye" : "eye-open",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-button",
            {
              staticStyle: { width: "100%", "margin-bottom": "30px" },
              attrs: { loading: _vm.loading, type: "primary" },
              nativeOn: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.handleLogin($event)
                },
              },
            },
            [_vm._v("Login")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }