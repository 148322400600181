// import request from '@/utils/request'

const routes = [
  {
    path: '/',
    component: 'Layout',
    redirect: '/setting/company',
    name: 'Home',
    alwaysShow: true,
    children: [{
      path: '/dashboard',
      name: 'Dashboard',
      component: 'views/dashboard/index',
      meta: { title: '首頁', icon: 'dashboard' }
    }]
  },
  {
    path: '/setting',
    component: 'Layout',
    redirect: '/setting/company',
    name: 'Setting',
    meta: { title: '系统管理', icon: 'el-icon-s-tools' },
    children: [
      {
        path: '/setting/company',
        name: 'Company',
        component: 'views/company/index',
        meta: { title: '機構管理' }
      },
      {
        path: '/setting/lender',
        name: 'Lender',
        component: 'views/lender/index',
        meta: { title: 'Lender管理' }
      },
      {
        path: '/setting/tag',
        name: 'Tag',
        component: 'views/tag/index',
        meta: { title: '標籤管理' }
      },
      {
        path: '/setting/banner',
        name: 'Banner',
        component: 'views/banner/index',
        meta: { title: 'Banner管理' }
      },
      {
        path: '/setting/image',
        name: 'Image',
        component: 'views/image/index',
        meta: { title: '圖片管理' }
      },
      {
        path: '/setting/seo',
        name: 'SEO',
        component: 'views/seo/index',
        meta: { title: 'SEO管理' }
      },
      {
        path: '/setting/page',
        name: 'Page',
        component: 'views/page/index',
        meta: { title: 'Landing Page管理' }
      },
      {
        path: '/setting/query',
        name: 'Query',
        component: 'views/query/index',
        meta: { title: 'Q&A管理' }
      },
      {
        path: '/setting/rate',
        name: 'InterestRateSetting',
        component: 'views/rate/index',
        meta: { title: 'Interest Rate Setting' }
      },
      {
        path: '/setting/subscribe',
        name: 'Subscriber',
        component: 'views/subscribe/index',
        meta: { title: '訂閱者電郵列表' }
      }
    ]
  },
  {
    path: '/product',
    component: 'Layout',
    redirect: '/product/loan',
    name: 'Product',
    meta: { title: '產品管理', icon: 'el-icon-help' },
    children: [
      {
        path: '/product/loan',
        name: '貸款管理',
        component: 'views/loan/index',
        meta: { title: '貸款管理' }
      },
      {
        path: '/product/mortgage',
        name: '按揭管理',
        component: 'views/mortgage/index',
        meta: { title: '按揭管理' }
      },
      {
        path: '/product/card',
        name: '信用卡管理',
        component: 'views/card/index',
        meta: { title: '信用卡管理' }
      },
      {
        path: '/product/account',
        name: '戶口管理',
        component: 'views/account/index',
        meta: { title: '戶口管理' }
      },
      {
        path: '/product/insurance',
        name: '保險管理',
        component: 'views/insurance/index',
        meta: { title: '保險管理' }
      }
    ]
  },
  {
    path: '/blog',
    component: 'Layout',
    redirect: '/blogType/list',
    name: 'Blog',
    meta: { title: '文章管理', icon: 'el-icon-document' },
    children: [
      {
        path: '/blogType/list',
        name: 'BlogType',
        component: 'views/blogType/index',
        meta: { title: '文章種類管理' }
      },
      {
        path: '/blog/list',
        name: 'BlogList',
        component: 'views/blog/index',
        meta: { title: '文章管理' }
      }
    ]
  },
  {
    path: '/userReward',
    component: 'Layout',
    redirect: '/userReward/list',
    name: 'Reward',
    meta: { title: '優惠管理', icon: 'el-icon-present' },
    children: [
      {
        path: '/userReward/list',
        name: 'UserRewardList',
        component: 'views/userReward/index',
        meta: { title: '用戶優惠管理' }
      }
    ]
  },
  {
    path: '/form',
    component: 'Layout',
    redirect: '/form/list',
    name: 'Form',
    meta: { title: '表單管理', icon: 'el-icon-receiving' },
    children: [
      {
        path: '/form/list',
        name: 'Form',
        component: 'views/form/index',
        meta: { title: 'Form' }
      },
      {
        path: '/formResult/list',
        name: 'Form Result',
        component: 'views/formResult/index',
        meta: { title: 'Form Result' },
        hidden: true
      }
    ]
  },
  {
    path: '/checkId',
    component: 'Layout',
    redirect: '/checkId/list',
    name: 'Check ID Result',
    meta: { title: 'CheckId List', icon: 'el-icon-check' },
    children: [
      {
        path: '/checkId/list',
        name: 'Check ID Result',
        component: 'views/checkId/index',
        meta: { title: 'Form' }
      }
    ]
  },
  {
    path: '/survey',
    component: 'Layout',
    redirect: '/survey/list',
    name: 'Survey',
    meta: { title: 'Survey', icon: 'el-icon-document' },
    children: [
      {
        path: '/survey/list',
        name: 'Form',
        component: 'views/survey/index',
        meta: { title: 'Form' }
      },
      {
        path: '/surveyResult/list',
        name: 'Survey Result',
        component: 'views/surveyResult/index',
        meta: { title: 'Survey Result' },
        hidden: true
      }
    ]
  },
  {
    path: '/customerService',
    component: 'Layout',
    redirect: '/customerService/list',
    name: 'Customer Service',
    meta: { title: 'CS管理', icon: 'el-icon-s-custom' },
    children: [
      {
        path: '/customerService/list',
        name: 'Form',
        component: 'views/customerService/index',
        meta: { title: 'Customer Service' }
      }
    ]
  }
]

export function getroutes() {
  return Promise.resolve(routes)
}
