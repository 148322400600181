module.exports = {

  title: 'MoneyPlaza CMS',

  /**
   * @type {boolean} true | false
   * @description Whether fix the header
   */
  fixedHeader: true,

  /**
   * @type {boolean} true | false
   * @description Whether show the logo in sidebar
   */
  sidebarLogo: true,

  uploadUrl: process.env.VUE_APP_BASE_API + '/file/upload'
}
