var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "top-menu-bar" },
    [
      _c(
        "el-scrollbar",
        [
          _c(
            "el-menu",
            {
              attrs: {
                "default-active": _vm.activeMenu,
                "background-color": _vm.variables.menuBg,
                "text-color": _vm.variables.menuText,
                "active-text-color": _vm.variables.menuActiveText,
                mode: "horizontal",
              },
            },
            [
              _vm._l(_vm.permission_routes, function (route) {
                return [
                  !route.hidden &&
                  _vm.getFirstShowingChild(route.children, route)
                    ? [
                        route.meta
                          ? _c(
                              "el-menu-item",
                              {
                                key: route.path,
                                attrs: { index: _vm.resolvePath(route) },
                                on: {
                                  click: function ($event) {
                                    return _vm.jumpTo(route)
                                  },
                                },
                              },
                              [
                                _c("item", {
                                  attrs: {
                                    icon: route.meta && route.meta.icon,
                                    title: route.meta.title,
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    : _vm._e(),
                ]
              }),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }